















@import '~variables'

.container
  display flex
  justify-content center
  align-items center
  width 100vw
  height 100vh
  background-color black
  color white
  .logo
    display flex
    flex-direction column
    .line
      &--first
        font-size 20rem
      &--second
        width 100%
        text-align center
        font-size 5.2rem
